import React from "react";

interface FooterProps {
}

const Footer: React.FC<FooterProps> = () => {

  return (
    <footer className='footer'>
      <div>
      </div>
    </footer>
  )
}

export default Footer;